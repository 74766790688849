<template>
  <div
    class="navbar relative flex-shrink-0 w-[280px] py-3 bg-[#0e1217] text-[#fafafa] sm:flex flex-col"
    :class="{ hidden: isActive }"
  >
    <div class="">
      <div class="flex md:h-[2rem]"></div>

      <ul class="flex flex-col items-start w-full">
        <li class="w-full" @click="toggle()">
          <router-link to="/" class="pl-2 pr-5 flex justify-between w-full">
            <div class="flex gap-2">
              <div class="icon">
                <font-awesome-icon icon="fa-solid fa-house-chimney-user" />
              </div>
              News
            </div>
          </router-link>
        </li>
        <li class="w-full" @click="toggle()">
          <router-link
            to="/appplace"
            class="pl-2 pr-5 flex justify-between w-full"
          >
            <div class="flex gap-2">
              <div class="icon">
                <font-awesome-icon icon="fa-solid fa-table-columns" />
              </div>
              Appplace
            </div>
          </router-link>
        </li>
        <li class="w-full" @click="toggle()">
          <router-link
            to="/communities"
            class="pl-2 pr-5 flex justify-between w-full"
          >
            <div class="flex gap-2">
              <div class="icon">
                <font-awesome-icon icon="fa-solid fa-table-columns" />
              </div>
              Communities
            </div>
          </router-link>
        </li>
        <li class="w-full" @click="toggle()">
          <router-link
            to="/marketplace"
            class="pl-2 pr-5 flex justify-between w-full"
          >
            <div class="flex gap-2">
              <div class="icon">
                <font-awesome-icon icon="fa-solid fa-table-columns" />
              </div>
              Marketplace
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="">
      <h4 class="px-3">Contribute</h4>
      <ul class="flex flex-col gap-[1] items-start w-full">
        <li class="w-full" @click="toggle()">
          <router-link
            to="/notifications"
            class="pl-2 flex gap-2 text-center items-center"
          >
            <div class="icon">
              <font-awesome-icon icon="fa-regular fa-bell" />
            </div>
            Report a story
          </router-link>
        </li>
        <li class="w-full" @click="toggle()">
          <AppLink
            to="https://business.campus.ctzn.ng"
            class="pl-2 flex gap-2 text-center items-center"
          >
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-gear" />
            </div>
            Register business
          </AppLink>
        </li>
      </ul>
    </div>

    <div class="">
      <h4 class="px-3">Manage</h4>
      <ul class="flex flex-col gap-[1] items-start w-full">
        <li class="w-full" @click="toggle()">
          <router-link
            to="/notifications"
            class="pl-2 flex gap-2 text-center items-center"
          >
            <div class="icon">
              <font-awesome-icon icon="fa-regular fa-bell" />
            </div>
            Notifications
          </router-link>
        </li>
        <li class="w-full" @click="toggle()">
          <router-link
            to="/settings"
            class="pl-2 flex gap-2 text-center items-center"
          >
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-gear" />
            </div>
            Settings
          </router-link>
        </li>

        <li class="w-full" @click="toggle()">
          <div to="/portal" class="pl-2 flex gap-2 text-center items-center">
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
            </div>
            Logout
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AppLink from './AppLink.vue';
export default {
  props: {
    isActive: Boolean,
  },

  methods: {
    toggle() {
      this.$emit('toggle');
    },
  },
  components: {
    AppLink,
  },
};
</script>

<style lang="scss" scoped>
.add-icon {
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  min-width: 1.24rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  li {
    padding: 0.3rem 0;
  }
}

li {
  font-weight: normal;
}

li:has(.router-link-exact-active) {
  .router-link-exact-active {
    color: #0e1217 !important;
    font-weight: bold;
  }
  background-color: #e77917;
}
</style>
