<template>
  <div
    class="sm:hidden side-nav fixed left-0 bottom-0 w-full px-[5%] shadow-lg bg-[#0e1217] text-[#e77917] overflow-hidden flex-shrink-0 z-40"
  >
    <ul
      class="flex flex-row gap-[1rem] items-center justify-between text-xs w-full"
    >
      <li class="w-full">
        <router-link
          to="/"
          class="flex flex-col gap-[.05rem] text-center items-center font-bold"
        >
          <div class="icon text-[1rem] font-bold">
            <font-awesome-icon icon="fa-solid fa-home" />
          </div>
          News
        </router-link>
      </li>
      <li class="w-full">
        <router-link
          to="/library"
          class="flex flex-col gap-[.05rem] text-center items-center font-bold"
        >
          <div class="icon text-[1rem] font-bold">
            <font-awesome-icon icon="fa-solid fa-book-bookmark" />
          </div>
          Library
        </router-link>
      </li>
      <li class="instant-icon sm:hidden flex-shrink-0 flex-grow">
        <router-link
          to="#"
          class="flex sm:flex-row flex-col gap-[.05rem] text-center items-center font-bold"
        >
          <font-awesome-icon icon="fa-solid fa-bolt" />
        </router-link>
      </li>
      <li class="w-full">
        <router-link
          to="/watch"
          class="flex sm:flex-row flex-col gap-[.05rem] text-center items-center font-bold"
        >
          <div class="icon text-[1rem] font-bold">
            <font-awesome-icon icon="fa-solid fa-tv" />
          </div>
          Watch
        </router-link>
      </li>
      <li class="w-full">
        <router-link
          to="/search"
          class="flex sm:flex-row flex-col gap-[.05rem] text-center items-center font-bold"
        >
          <div class="icon text-[1rem] font-bold">
            <font-awesome-icon icon="fa-solid fa-search" />
          </div>
          Search
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NavigationComponent',
}
</script>

<style scoped lang="scss">
.side-nav {
  a {
    color: #fafafa;
  }
}
.instant-icon {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  @apply shadow-lg;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.67rem;

  a {
    color: #e77917 !important;
  }
}

li {
  padding: 0.5rem 0;
  border-top: 3px solid transparent;

  &:has(.router-link-exact-active) {
    .router-link-exact-active {
      color: #e77917 !important;
    }
    border-color: #e77917;
  }
}
</style>
